<template>
  <header>
    <a href="/"><img class="logo" src="/logo_horizontal_serif.png" /></a>
    <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/shop">Shop</router-link>
      <router-link to="/contact">Contact and Quotes</router-link>
      <router-link to="/commissions">Commissions</router-link>
      <!-- <router-link to="/accessories">Accessories</router-link> -->
    </nav>
  </header>
  <main>
    <router-view />
  </main>
  <footer>
    <div>
      This site uses no cookies, analytics, or any tracking whatsoever ✨
    </div>
    <div>© 2024, <a href="mailto:rob@kirberich.uk">Robert Kirberich</a></div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {},
});
</script>

<style lang="scss">
@import "./assets/main.scss";
</style>
