import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: HomeView,
    },
    {
      path: '/shop',
      component: () => import('@/views/ShopView.vue'),
    },
    {
      path: '/about',
      component: () => import('@/views/HomeView.vue'),
    },
    {
      path: '/contact',
      component: () => import('@/views/ContactView.vue'),
    },
    {
      path: '/commissions',
      component: () => import('@/views/CommissionsView.vue'),
    },
  ],
})
