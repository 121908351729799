import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src", "alt", "title"]
const _hoisted_5 = ["src", "alt", "title"]
const _hoisted_6 = { class: "card-content" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.link)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.link
          }, [
            (_ctx.link)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.image,
                  alt: _ctx.alt,
                  title: _ctx.alt
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ], 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.image,
            alt: _ctx.alt,
            title: _ctx.alt
          }, null, 8, _hoisted_5))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h3", null, [
        (_ctx.link)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.link
            }, _toDisplayString(_ctx.title), 9, _hoisted_7))
          : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.title), 1))
      ]),
      _createElementVNode("p", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}