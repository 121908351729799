
import { defineComponent } from "vue";

const __default__ = defineComponent({
  name: "VerticalCard",
  props: {
    title: { type: String, required: true },
    link: { type: String, required: false, default: "" },
    image: { type: String, required: true },
    alt: { type: String, required: false },
    aspectRatio: { type: Number, required: false, default: 1 / 1.6 },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "26deef83": (_ctx.aspectRatio)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__