import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hero-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "hero",
    style: _normalizeStyle({ backgroundImage: `url('${_ctx.image}')` })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("h2", null, _toDisplayString(_ctx.subtitle), 1)
    ])
  ], 4))
}