<template>
  <div class="hero" :style="{ backgroundImage: `url('${image}')` }">
    <div class="hero-content">
      <h1>{{ title }}</h1>
      <h2>{{ subtitle }}</h2>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeroImage",
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
    image: { type: String, required: true },
  },
});
</script>

<style scoped lang="scss">
.hero {
  padding-top: 0;
  margin-top: 0;
  width: 100%;
  height: 30rem;
  background-size: cover;
  background-position: bottom;
  padding: 2rem;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: white;
}
h1 {
  font-size: 3rem;
  font-weight: 400;
  margin: 0.5rem;
}
h2 {
  font-size: 2rem;
  font-weight: normal;
}
</style>
