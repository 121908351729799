import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-content"
}
const _hoisted_2 = { class: "card-image" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["src", "alt", "title"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src", "alt", "title"]
const _hoisted_7 = {
  key: 1,
  class: "card-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slide = _resolveComponent("slide")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classList(['card']))
  }, [
    (_ctx.align == 'right')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.images.length > 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_carousel, { "items-to-show": 1 }, {
              addons: _withCtx(() => [
                _createVNode(_component_navigation),
                _createVNode(_component_pagination)
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
                  return (_openBlock(), _createBlock(_component_slide, { key: image }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: image,
                        alt: _ctx.alt,
                        title: _ctx.alt
                      }, null, 8, _hoisted_4)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.images[0],
              alt: _ctx.alt,
              title: _ctx.alt,
              class: _normalizeClass(
            _ctx.orientation === 'portrait'
              ? 'portrait single-image'
              : 'landscape single-image'
          )
            }, null, 10, _hoisted_6)
          ]))
    ]),
    (_ctx.align == 'left')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}