<template>
  <HeroImage
    title="Kirberich Woodworking"
    subtitle="Custom furniture from a tiny workshop in Edinburgh"
    image="/images/desk_handle_detail_wide.jpg"
  />
  <div class="statement-cards">
    <VerticalCard
      title="Handmade Quality"
      alt="Vanity - solid oak"
      image="/images/side_back_square.jpg"
      >I build every piece of furniture myself, prioritising quality over speed,
      to make things that last.</VerticalCard
    >

    <VerticalCard
      title="Small or Large, Simple or Complex"
      alt="Desk and Shelf, welded steel frame and plywood"
      image="/images/plant_desk.jpg"
      >Anything from simple shelves and plywood desks to complex designs and
      large solid timber furniture.</VerticalCard
    >

    <VerticalCard title="Honest Materials" image="/images/walnut_board.jpg"
      >I use only quality plywood and sustainable, preferably local, hardwoods
      like oak and walnut for my furniture.</VerticalCard
    >
  </div>
  <div class="contact-callout">
    <HorizontalCard :images="['/images/standing_desk_side_folded_small.jpg']">
      <h2>Contact Me</h2>
      <p>
        No matter if you know exactly what you like or just have a rough idea -
        Reach out via instagram, email, or the contact page to discuss your
        project, big or small.
      </p>

      <p>
        <a href="https://instagram.com/kirberich">Instagram</a> ·
        <a href="mailto:rob@kirberich.uk">Email</a> ·
        <a href="/contact">Contact form</a>
      </p>
    </HorizontalCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeroImage from "@/components/HeroImage.vue";
import VerticalCard from "@/components/VerticalCard.vue";
import HorizontalCard from "@/components/HorizontalCard.vue";

export default defineComponent({
  name: "HomeView",
  props: {
    msg: String,
  },
  components: { HeroImage, VerticalCard, HorizontalCard },
});
</script>

<style scoped>
.statement-cards {
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}

@media only screen and (min-width: 600px) {
  .statement-cards {
    display: flex;
    justify-content: center;
    max-width: 82rem;
  }
}
</style>
